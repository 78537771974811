import React from "react";
import SectionWrapper from "../Reusables/SectionWrapper";
import SectionTitle from "../Reusables/SectionTitle";
import SectionComponent from "../Reusables/SectionComponent";

export default function PreviousExperience() {
  return (
    <SectionWrapper id="PreviousExperience">
      <SectionTitle>Work Experience</SectionTitle>
      <SectionComponent
        date="February 2023 - Current"
        title="Full Stack Programming Teacher"
        subtitle1="Senac, Rio de Janeiro, BR"
      >
        Responsible for teaching young adults, planning activities, student
        assessment and mediation. <br />
        Tech Stack: React and NodeJS / HTML, CSS, Javascript / Object Oriented
        Programming / Bootstrap / Database / Software Testing.
      </SectionComponent>
      <SectionComponent
        date="June 2023 - Current"
        title="B2B Customer Service Specialist"
        subtitle1="goTRG, Florida, USA - Remote"
      >
        Experienced B2B Customer Service Specialist skilled in providing
        top-tier support. Proficient in product knowledge, effective
        communication, and problem-solving. I have successfully ensured customer
        satisfaction in various projects.
        {/*[add this after a few months:] long-term projects.*/}
      </SectionComponent>
      <SectionComponent
        date="July 2022 - December 2022"
        title="Web Developer - Elementor and React"
        subtitle1="Soul Media, Rio de Janeiro, BR"
      >
        Developed many websites and responsive aplications, in Wordpress, with
        Elementor and with React, for more robust aplications. Most of the user
        interfaces were also designed by me. I also did the hosting setup for
        the websites, including their domains and DNS.
      </SectionComponent>
      <SectionComponent
        date="December 2021 - June 2022"
        title="Freelance Front-End Web Developer"
        subtitle1="Freelancer, Rio de Janeiro, BR"
      >
        Developed React interfaces in partnership with a Back-End developer.
        Projects developed include: administration systems for schools, with
        student registration, course catalog and file uploading. Server
        communication made with API calls.
      </SectionComponent>
      <SectionComponent
        date="2016 - 2021"
        title="Freelance Product Designer"
        subtitle1="Freelancer, Rio de Janeiro, BR"
      >
        Created 3D models and 2D graphics attending the needs of the client.
        Including 3D rendering, exploded views and technical drawings. Also
        image creation and retouching on Photoshop. I also did video editing on
        Adobe Premiere and vector works on Adobe Illustrator.
      </SectionComponent>
      <SectionComponent
        date="2020 - 2021"
        title="Ecommerce Manager"
        subtitle1="Mercado Libre, Rio de Janeiro, BR"
      >
        Owner of an Online store with more than 4000 products and hundreds of
        monthly customers. Main activities were: customer contact and problem
        solving, creating and editing product listings.
      </SectionComponent>
      {/* <SectionComponent
        date="2010 - 2020"
        title="Online Content Creator"
        subtitle1="YouTube, Rio de Janeiro, BR"
        link="https://www.youtube.com/@UlyssesPinheiro"
      >
        Development of projects and video editing, of various themes, including:
        wood-working, arts and crafts. My channel has now more than 200k
        subscribers.
      </SectionComponent> */}
    </SectionWrapper>
  );
}
